.landing {
  font-family: "Poppins", sans-serif;

  .web {
    height: 100vh;
    width: 100vw;
    margin: 0px;
    // background-color: #0c51ca;
    position: relative;
    margin: auto;

    .appbar {
      background-color: #0c51ca;

      .toolbar {
        position: relative;
        margin: auto;
        height: 100px;
        width: 80vw;

        .logo {
          height: 50px;
          margin-right: 0.5rem;
          vertical-align: middle;
          cursor: pointer;
        }

        .selected {
          margin-left: auto;
          vertical-align: middle;
          cursor: pointer;
          color: white;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }
        }

        .link {
          vertical-align: middle;
          cursor: pointer;
          color: white;
          opacity: 0.7;
          text-decoration: none;
          margin-left: 20px;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }

    .left-item {
      background-color: white;
      height: calc(100vh - 140px);
      display: flex;
      justify-content: center;
      align-items: center;

      .app-image {
        width: 700px;
      }
    }

    .right-item {
      height: calc(100vh - 140px);

      display: flex;
      justify-content: center;
      align-items: center;

      .right-content {
        margin-right: 150px;

        .title {
          font-weight: 700;
          font-size: 1.7rem;
          margin-bottom: 40px;
        }

        .body {
          margin-bottom: 40px;
        }
      }
    }

    .footer {
      height: 40px;
      background-color: rgb(241, 239, 239);

      padding-left: 50px;
      padding-right: 50px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .copyright {
        font-size: 0.8rem;
        font-weight: 500;
      }

      .options {
        display: flex;

        .link-cover {
          text-decoration: none;
          color: black;
        }

        .option-item {
          font-size: 0.8rem;
          font-weight: 500;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;

      .appbar {
        background-color: #0c51ca;

        .toolbar {
          position: relative;
          margin: auto;
          height: 100px;
          width: 80vw;

          .logo {
            height: 50px;
            margin-right: 0.5rem;
            vertical-align: middle;
            cursor: pointer;
          }

          .menu-icon {
            margin-left: auto;
          }
        }
      }

      .content {
        text-align: center;
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;

        .title {
          font-weight: 700;
          font-size: 1.6rem;
          margin-bottom: 20px;
        }

        .app-image {
          width: 100%;
        }

        .body {
          margin-bottom: 40px;
        }
      }

      .footer {
        text-align: center;
        margin-bottom: 20px;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}
