.linkx {
  font-family: "Poppins", sans-serif;

  .web {
    height: 100vh;
    width: 100vw;
    margin: 0px;
    position: relative;
    margin: auto;

    .appbar {
      background-color: #0c51ca;

      .toolbar {
        position: relative;
        margin: auto;
        height: 100px;
        width: 80vw;

        .logo {
          height: 50px;
          margin-right: 0.5rem;
          vertical-align: middle;
          cursor: pointer;
        }

        .first {
          margin-left: auto;
          vertical-align: middle;
          cursor: pointer;
          color: white;
          opacity: 0.7;
          text-decoration: none;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }

        .selected {
          margin-left: 20px;
          vertical-align: middle;
          cursor: pointer;
          color: white;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }
        }

        .link {
          vertical-align: middle;
          cursor: pointer;
          color: white;
          opacity: 0.7;
          text-decoration: none;
          margin-left: 20px;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }

    .body-wrapper {
      background-color: #0c51ca;
      height: calc(100vh - 140px);
      width: 100vw;

      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        .img-wrapper {
          width: 50vw;

          display: flex;
          justify-content: center;
          .link-image {
            height: 10rem;
          }
        }

        .head {
          text-align: center;
          color: white;
          margin-top: 50px;
          margin-bottom: 50px;
          font-weight: 600;
        }

        .col {
          width: 50vw;

          display: flex;
          justify-content: space-between;

          .spacer {
            width: 2px;
            background-color: white;
            border-radius: 10px;
            margin-left: 20px;
            margin-right: 20px;
          }

          .col-item {
            width: 25vw;
            color: white;
            border: 1px solid white;
            border-radius: 3px;
            padding: 12px;

            .col-head {
              font-weight: 600;
              text-align: center;
            }
          }
        }
      }
    }

    .footer {
      height: 40px;
      background-color: rgb(241, 239, 239);

      padding-left: 50px;
      padding-right: 50px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .copyright {
        font-size: 0.8rem;
        font-weight: 500;
      }

      .options {
        display: flex;

        .link-cover {
          text-decoration: none;
          color: black;
        }

        .option-item {
          font-size: 0.8rem;
          font-weight: 500;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;

      .appbar {
        background-color: #0c51ca;

        .toolbar {
          position: relative;
          margin: auto;
          height: 100px;
          width: 80vw;

          .logo {
            height: 50px;
            margin-right: 0.5rem;
            vertical-align: middle;
            cursor: pointer;
          }

          .menu-icon {
            margin-left: auto;
          }
        }
      }

      .body-wrapper {
        background-color: #0c51ca;
        height: calc(100vh - 100px);
        width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;

        .content {
          .img-wrapper {
            display: flex;
            justify-content: center;

            .link-image {
              height: 6.5rem;
            }
          }

          .head {
            text-align: center;
            color: white;
            margin-top: 50px;
            margin-bottom: 50px;
            font-weight: 600;
          }

          .col-item-out {
            color: white;
            margin-left: 50px;
            margin-right: 50px;

            .col-head {
              font-weight: 600;
              text-align: center;
            }
          }

          .spacer-out {
            height: 40px;
          }
        }
      }

      .footer {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}
