.tnc {
  font-family: "Poppins", sans-serif;

  .web {
    height: 100vh;
    width: 100vw;
    margin: 0px;
    position: relative;
    margin: auto;

    .appbar {
      background-color: #0c51ca;

      .toolbar {
        position: relative;
        margin: auto;
        height: 100px;
        width: 80vw;

        .logo {
          height: 50px;
          margin-right: 0.5rem;
          vertical-align: middle;
          cursor: pointer;
        }

        .first {
          margin-left: auto;
          vertical-align: middle;
          cursor: pointer;
          color: white;
          opacity: 0.7;
          text-decoration: none;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }

        .selected {
          margin-left: 20px;
          vertical-align: middle;
          cursor: pointer;
          color: white;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }
        }

        .link {
          vertical-align: middle;
          cursor: pointer;
          color: white;
          opacity: 0.7;
          text-decoration: none;
          margin-left: 20px;

          .btn {
            font-family: "Poppins", sans-serif;
            color: white;
            text-transform: none;
          }

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }

    .content-container {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-top: 50px;
      margin-bottom: 50px;

      .content {
        width: 70vw;

        .title {
          font-weight: 700;
          font-size: 1.7rem;
          margin-bottom: 40px;
          text-align: center;
        }

        .body {
          margin-bottom: 40px;
        }
      }
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;

      .appbar {
        background-color: #0c51ca;

        .toolbar {
          position: relative;
          margin: auto;
          height: 100px;
          width: 80vw;

          .logo {
            height: 50px;
            margin-right: 0.5rem;
            vertical-align: middle;
            cursor: pointer;
          }

          .menu-icon {
            margin-left: auto;
          }
        }
      }

      .content {
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;

        .title {
          text-align: center;
          font-weight: 700;
          font-size: 1.6rem;
          margin-bottom: 20px;
        }

        .body {
          margin-bottom: 40px;
        }
      }
    }
  }
}
